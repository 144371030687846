/** variables */
@import "variables";

$primary-color: #a64167; /* Define your primary color */
/* Define your secondary color */
$secondary-color: #08a95c;
/* Define your third color */
$third-color: #3b3b3b;
/* Define your white color */
$white-color: #fffffe;
/* Define your text color */
$text-color: #3b3b3b;
/* Define your text white color */
$text-white-color: #fffffe;
/* Define your link color */
$link-color: #007bff;
/* Define your link hover color */
$link-hover-color: #0056b3;
/* Define your body background color */
$body-background: $white-color;
/* Define your smart action button background color */
$smart-action-button-background: $primary-color;
/* Define your smart action button text color */

$body-dark-background-image: url(./../images/Background/car-69.jpg);
$body-background-image: url(../images/Background/fiat-500.jpg);

:root {
  --primary-color: #a64167;
  /* */
  --secondary-color: #08a95c;
  /* third color */
  --third-color: #3b3b3b;
  /* Yellow */
  --white-color: #fffffe;
  /* Text Colors */
  --text-color: #3b3b3b;
  /* Text White Color ***/
  --text-white-color: #fffffe;
  /* Main text color */
  --link-color: #007bff;
  /* Color for hyperlinks */
  --link-hover-color: #0056b3;
  /* */
  --body-background: var(--white-color);
  /** buttons colors **/
  --smart-action-button-background: var(--primary-color-side);
  --smart-action-button-text: var(--white-color);
  /*** pagination colors ***/
  --smart-page-active-color: var(--primary-color-side);
  --smart-page-disabled-color: var(--third-color);
  /**** ****/
  --smart-text-opacity: 0.9;
  --smart-bg-opacity: 0.9;

  /***/
  --bs-popover-body-padding-y: 0.4em;
  /***/
  --bs-popover-body-padding-y: 0.4em;
  --primary-font-family: "Baumans";
  --fredoka-font-family: "Baumans";
  --fresca-font-family: "Baumans";

  --roboto-font-family: "roboto";
  --inter-font-family: "Baumans";
  --baumans-font-family: "Baumans";
  --alex-font-family: "Baumans";
}

@font-face {
  font-family: "DMSans";
  src: url("../fonts/DM_Sans/static/DMSans-Regular.ttf") format("truetype");
  /* Additional font weights and styles */
}

@font-face {
  font-family: "Fredoka";
  src: url("../fonts/Fredoka/static/Fredoka-Regular.ttf") format("truetype");
  /* Additional font weights and styles */
}
@font-face {
  font-family: "teko";
  src: url("../fonts/Teko/static/Teko-Regular.ttf") format("truetype");
  /* Additional font weights and styles */
}
@font-face {
  font-family: "lob";
  src: url("../fonts/Lobster/Lobster-Regular.ttf") format("truetype");
  /* Additional font weights and styles */
}
@font-face {
  font-family: "liber";
  src: url("../fonts/Libre_Baskerville/LibreBaskerville-Regular.ttf") format("truetype");
  /* Additional font weights and styles */
}
@font-face {
  font-family: "monoton";
  src: url("../fonts/Monoton/Monoton-Regular.ttf") format("truetype");
  /* Additional font weights and styles */
}
@font-face {
  font-family: "concrete";
  src: url("../fonts/Concert_One/ConcertOne-Regular.ttf") format("truetype");
  /* Additional font weights and styles */
}



@font-face {
  font-family: "Fresca";
  src: url("../fonts/Fresca/Fresca-Regular.ttf") format("truetype");
  /* Additional font weights and styles */
}

@font-face {
  font-family: "Inter";
  src: url("../fonts/Inter/Inter-Regular.ttf") format("truetype");
  /* Additional font weights and styles */
}

@font-face {
  font-family: "roboto";
  src: url("../fonts/roboto/Roboto-Regular.ttf") format("truetype");
  /* Additional font weights and styles */
}

@font-face {
  font-family: "AlexBrush";
  src: url("../fonts/Alex_Brush/AlexBrush-Regular.ttf") format("truetype");
  /* Additional font weights and styles */
}

@font-face {
  font-family: "Baumans";
  src: url("../fonts/Baumans/Baumans-Regular.ttf") format("truetype");
  /* Additional font weights and styles */
}

.dm_sans-font-family {
  font-family: var(--baumans-font-family);
}

.fredoka-font-family {
  font-family: var(--baumans-font-family);
}

.fresca-font-family {
  font-family: var(--baumans-font-family);
}

.inter-font-family {
  font-family: var(--baumans-font-family);
}

.roboto-font-family {
  font-family: var(--baumans-font-family);
}

.alex-font-family {
  font-family: var(--baumans-font-family);
}

.baumans-font-family {
  font-family: var(--baumans-font-family);
}
