@import "../../assets/scss/index.scss";
@import "../../assets/scss/variables.scss";

.efl-main-sidenav {

}

.smart-dark-efl-container {
  // background: $body-background-image;
  background-color: rgba(255, 255, 255, 0.8);
  transition: background-color 0.3s ease, color 0.3s ease;
  background-size: 100% 100%;
  min-height: 100vh;
  background-attachment: fixed;
  .efl-main-header {
    left: 80px;
    height: 60px;
    position: fixed;
    top: 0;
    width: calc(100% - 80px);
    transition: left 0.3s ease;
    background: white;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3), 0 8px 20px rgba(0, 0, 0, 0.15);
    background-size: cover;
    z-index: 956;
    color: white !important;
    &.expanded {
      width: calc(100% - 200px);
      left: 200px;
      @media screen and (max-width: 768px) {
        left: 0;
        width: 100%;
      }
      // Extra small devices (e.g. phones)
      @media screen and (max-width: 480px) {
        left: 0;
        width: 100%;
      }
    }
    .navbar {
      //  border-bottom: 1px solid $primary-color;
      height: 100%;
      // background-color: rgb(171, 29, 83);
      background-color:white;
      color: $primary-color;
    }
    @media screen and (max-width: 768px) {
      left: 0;
      width: 100%;
    }
    // Extra small devices (e.g. phones)
    @media screen and (max-width: 480px) {
      left: 0;
      width: 100%;
    }
  }

  .has-text-white {
    color: white !important;
  }
  .efl-main-div {
    margin-left: 40px;
    margin-top: 55px;
    padding: 10px;
    // padding-left: 20px;
    transition: margin-left 0.3s ease;
    min-height: 100vh;
    background-color: rgba(255, 255, 255, 0.9);
    min-width: calc(100% - 136px);
    width: 100%;
    // border: 2px solid $primary-color;
    // margin: 50px;
    &.expanded {
      margin-left: 178px;
      width: calc(100% - 136px);
    }
    @media screen and (max-width: 768px) {
      margin-left: 0 !important;
      width: 100%;
      padding: 5px;
    }

    // Extra small devices (e.g. phones)
    @media screen and (max-width: 480px) {
      margin-left: 0 !important;
      width: 100%;
      padding: 5px;
    }
  }
  .efl-main-nav {
    .navbar-item {
      font-size: 1.2em;
    }
  }
  .header-brand-logo {
    margin-bottom: 5px;
    padding: 5px;
    height: 150px; 
    border-radius: 50%; 
    img {
      height: 100%;
      display: block;
      object-fit: fill !important;border-radius: 50%;
    }
  }

  /*** side nav styles ***/
  // Base sidenav styles
  .smart-sidenav {
    width: 80px; // Shrinked width
    height: 100vh;
    background-color: #ffffff;
    // background-color: rgba(0, 0, 0, 0.6);
    // background-color: $primary-color;
    position: fixed;
    top: 0;
    left: 0;
    transition: width 0.3s ease;
    transition: display 0.3s ease;
    display: flex;
    flex-direction: column;
    // align-items: center;
  // box-shadow: 4px 0 12px rgba(0, 0, 0, 0.5), 8px 0 20px rgba(0, 0, 0, 0.15);
    border-right: 1px solid $primary-color;
    &.expanded {
      width: 210px; // Expanded width on hover

      @media screen and (max-width: 768px) {
        position: fixed;
        top: 0;
        width: 200px;
        display: flex;
        z-index: 1001;
        transition: width 0.3s ease;
        transition: display 0.3s ease;
        .sds-dropdown-container{
          background-color:rgba(255, 255, 255, 0.8);
          margin: 0;
          width: 100%;
          border:  1px solid $primary-color;
        }
        .navbar-item {
          color: $primary-color !important;
          font-weight: 500;
        }
      }

      // Extra small devices (e.g. phones)
      @media screen and (max-width: 480px) {
        position: fixed;
        top: 0;
        width: 250px;
        display: flex;
        z-index: 1001;
        background-color: rgba(255, 255, 255, 0.9);
        transition:  0.3s ease;
        transition: display 0.3s ease;
        opacity: 1;
        transition: left 0.3s ease-in-out, opacity 0.3s ease-in-out;
      }
    }

    .brand-logo {
      // height:60px;
      margin-bottom: 20px;
//padding: 10px;
      // background-color: #ffffff;
      // background-color: rgb(0,0,1);
      // filter: blur(5px);
      background-color: $white-color;
      height: 60px;

      img {
        height: 100%;
        display: block;
        object-fit: fill !important;
        margin-bottom: 12px;
      }
    }

    &.expanded .brand-logo img {
      max-width: 100%;
    }

    .sidenav-link {
      width: 100%;
      padding: 7.5px;
      text-decoration: none;
      color:  $primary-color;
      display: flex;
      align-items: center;
      transition: all 0.5s;
      font-weight: 600;
      &:hover {
        background-color:$primary-color;
        color: #ffffff;
        // border-top: 1px solid #91076d;
        border-bottom-right-radius: 40px !important;
        border-top-right-radius: 5px;
        border-radius: 5px;
      }

      .icon {
        font-size: 24px;
        margin-right: 16px;
        // margin-top: 5px;
      }

      .link-text {
        display: none; // Initially hidden
      }

      &.active-link {
        background-color: white;
        color: $primary-color;
        // border-top: 1px solid #91076d;
        border-bottom-right-radius: 40px !important;
        border-top-right-radius: 5px;
        border-radius: 5px;
      }
    }

    // Show the text when sidenav is expanded
    &.expanded .link-text {
      display: inline-block;
    }

    // Mobile view styles
    @media screen and (max-width: 768px) {
      display: none;
    }

    // Extra small devices (e.g. phones)
    @media screen and (max-width: 480px) {
      display: none;
    }
  }

  .gradient-blue {
    background: linear-gradient(
      45deg,
      rgba(106, 17, 203, 1),
      rgba(37, 117, 252, 1)
    );
  }

  .gradient-pink {
    background: linear-gradient(
      45deg,
      rgba(255, 117, 140, 1),
      rgba(255, 126, 179, 1)
    );
  }

  .gradient-green {
    background: linear-gradient(
      45deg,
      rgba(67, 233, 123, 1),
      rgb(56, 249, 215, 1)
    );
  }

  .gradient-orange {
    background: linear-gradient(
      45deg,
      rgba(255, 154, 68, 1),
      rgba(252, 96, 118, 1)
    );
  }
}

.smart-elf-notification .dropdown-content {
  border: 1px solid #1c04f0;
  font-size: 14px;
  padding: 0 !important;
  width: 360px !important;
}

.navbar-item,
.navbar-link {
  position: relative !important;

  .smart-elf-notification {
    span {
      .user-bade {
        background-color: #f427c0 !important;
        border-radius: 50% !important;
        color: #fff;
        font-size: 12px;
        padding: 0 5px;
        //   position: absolute;
        //   right: -5px;
        //   top: -15px;
        z-index: 10;
      }
    }
  }
}

.smart-efl-container {
  // Mobile view styles
  @media screen and (max-width: 768px) {
    padding: 0 !important;
  }
}

.sd-efl-modal {
  .modal-card {
    @media screen and (max-width: 768px) {
      width: 100% !important;
    }
  }
}

// ToggleSwitch

.toggle-switch {
  width: 45px;
  height: 25px;
  margin-top:3px ;
  border-radius: 15px;
  background-color: #ccc;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  // Dark mode background
  &.dark {
    background-color: #333;

    // Move the toggle thumb to the right when dark mode is active
    .toggle-thumb {
      transform: translateX(22px);
    }
  }

  .toggle-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    top: 2px;
    left: 2px;
    transition: transform 0.3s ease-in-out;
  }
}

.scrollable-table {
  overflow-x: auto;
  /* Enables horizontal scrolling */
  white-space: nowrap;
  /* Prevents table contents from wrapping */
  height: 75vh;
}
.sds-admin-side-bar-list{
  // margin-bottom: 10px;
  // padding: 10px;
  // list-style: none;
  // background-color: #1c04f0;
  // color: white !important;
  // width: 100% !important;
  // border-radius:1px ;
  // box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
  text-align: left !important;

}
@media (max-width: 768px) {
 .smart-mobile-header-logo{
  img{
    height: 40px !important;
    width: 130px !important;
  }
 }
}
.site-heder-title{
  
  color: $header-color !important;
  
}
