@import "../../assets/scss/index.scss";
@import "../../assets/scss/variables.scss";

.smart-user-main-div {
  margin-top: 10px;
  //   padding: 0px 10px;
  transition: margin-left 0.3s ease;
  min-height: 100vh;
  background-color: rgba(255, 255, 255, 0.9);
  // min-width: calc(100% - 136px);
  width: 100%;
  // border: 2px solid $primary-color;
  // margin: 50px;

  @media screen and (max-width: 768px) {
    margin-left: 0 !important;
    width: 100%;
    padding: 5px;
  }

  // Extra small devices (e.g. phones)
  @media screen and (max-width: 480px) {
    margin-left: 0 !important;
    width: 100%;
    padding: 5px;
  }
}
.card-container {
  perspective: 1000px;
}

.flip-card {
  width: 300px;
  height: 400px;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  position: relative;

  &:hover {
    transform: rotateY(180deg);
  }
}

.card-face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-front {
  background-size: cover;
  background-position: center;
}

.card-back {
  background-color: #f5f5f5;
  color: #363636;
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  border: 2px solid #ffd700;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}


.smart-header-sds {
  background-color: white;
  padding: 1rem;
  max-height: 4.2rem;
  .smart-header-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: black;
  }
  .smart-sds-headed {
    display: "flex";
    justify-content: "space-between";
    flex: 1;
  }
  .smart-header-content {
    // display: flex;
    // align-items: center;
    gap: 1 rem;
    color: #fff;
    .active-navbar-item {
      color: $primary-color !important;
      text-decoration: none;
      transition: color 0.3s ease;
      font-size: 1.2rem;
      font-weight: 600;
    }
    .navbar-item {
      color: black;
      text-decoration: none;
      transition: color 0.3s ease;
      font-size: 1.2rem;
      font-weight: 500;
      font-family: concrete !important;
      margin: 0 0.3rem;
    }

    a {
      color: black;
      text-decoration: none;
      transition: color 0.3s ease;
      font-size: 1.2rem;
      font-weight: 600;
      font-family: Fredoka;
    }
    a:hover {
      color: $primary-color;
    }
    a.navbar-item:hover {
      background-color: transparent;
    }
  }
}
.smart-arco-footer {
  max-height: 5rem;
}
.cta-button {
  // margin-top: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  background: $primary-color !important;
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgb(37, 5, 134);
  font-family: $primary-font;

  &:hover {
    background: darken(white, 10%);
  }
}
.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(8px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
}

.navbar-item.active-navbar-item {
  color: #ffdd57 !important; // Highlight active link
}

  


  .navbar-menu-mobile.is-active {
    transform: translateX(0);
  }




// .sds-hover-dropdown {
//    color: red !important;
//   position: relative;
// }:hover .sds-navbar-dropdown {
//   display: block !important;
// }
.sds-dropdown-container {
  position: relative;

  .sds-hover-dropdown {
    // color: red !important;
  }

  .sds-navbar-dropdown {
    display: none !important; // Hide by default
    height: auto !important;
    background-color: #f5f5f5;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 280px !important; // Fixed: Added "px"
    z-index: 10;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease-in-out;
    padding: 0.5rem;
    border-radius: 10px;
    background-image: linear-gradient(
      to right top,
      #f376bd,
      #e78fd9,
      #daa5ed,
      #cfbaf9,
      #caccff,
      #bfd4ff,
      #b7dbff,
      #b4e1ff,
      #9be2fe,
      #80e3f8,
      #67e3ec,
      #57e3da
    );
  }

  &:hover .sds-navbar-dropdown {
    display: block !important;
  }
}

.header-toggle-button{
  margin-left: auto;
  cursor: pointer;
  transition: transform 0.3s ease;
  font-size: 1.5rem;
  font-weight: 600;
  font-family: Fredoka;
  color: black;
  &:hover{
    transform: scale(1.1);
  }
  
}
.navbar-brand{
  min-height: 0;
}
.navbar-item-image{
  // height: 100px !important;
  // width: 166px !important;
  min-height: 60px !important;
  // object-fit: cover;
}

@media (max-width: 768px) {
  .flip-card {
    width: 250px;
    height: 350px;
  }
  .navbar-brand{
    min-height: 1rem;
  }
  .navbar-item-image{
    width:130px  !important;
min-height: 40px !important;
  }
  .navbar-end{
    display: block !important;
  }
}

.footer-quick-link{
  margin-right: 1rem;
  &:hover{
    color: white;
  }
}