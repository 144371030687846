// Variables
@import "../../assets/scss/variables.scss";
// $primary-color: #3273dc;
// $secondary-color: #4a4a4a;
// $background-color: #f5f5f5;
$shadow-color: rgba(0, 0, 0, 0.05);
$shadow-hover-color: rgba(0, 0, 0, 0.1);
$gradient-start: $primary-color;
$gradient-end: $secondary-color;
$gradient-hover-start: lighten($primary-color, 10%);
$gradient-hover-end: lighten($secondary-color, 10%);
$value-number-color: rgba($primary-color, 0.1);
$border-radius: 8px;

// Global styles
.landing-page {
  font-family: "Poppins", sans-serif;
  //   text-align: center;
  background: "#f5f5f5";
}

// Hero Section
// .home-first-section{
//   background: url(../../assets/images/Background/expert.png);
//   background-position: center;
//   background-size: cover;
//   max-height: 100vh;
// }
.hero-section {
  // //   padding: 100px 20px;
  //   background: linear-gradient(135deg, $primary-color, darken($primary-color, 15%));
  //   color: white;
  max-height: 100vh;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  overflow: hidden;
  background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);


  position: relative;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    // background-color: rgba(0, 0, 0, 0.6);
    // background: url(../../assets/images/Background/wave-1.jpg);
    z-index: -1;

  }

  .landing-page-left-container {
    padding: 4rem 0rem;
    padding-left: 5rem;
  }

  .best-text {
    color: rgb(37, 5, 134);
    font-size: 1.8rem;
    font-weight: 600;
  }

  h1 {
    font-size: 3.5rem;
    color: white;
    // margin-bottom: 10px;
    font-weight: 800;
    font-family: $primary-font;
  }

  .landing-page-sub-text {
    font-size: 1rem;
    color: #383434;

  }

  .cta-button {
    margin-top: 20px;
    padding: 10px 40px;
    font-size: 1rem;
    background: $primary-color !important;
    color: white;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    border-radius: 10px;
    box-shadow: 2px 2px 4px rgb(37, 5, 134);

    &:hover {
      background: darken(white, 10%);
    }
  }

  .landing-first-right-container {
    // height: 90vh;
    // background: url(../../assets/images/mask-shape.png);
    background-position: center;
    background-size: cover;
    // padding: 3rem;
    display: flex;
    justify-content: center;
    align-items: end;

    .smart-sds-landing-image {
      // height: 100vh;
      // padding: 0;
      // margin: 0;

    }


  }

}

@media (max-width: 768px) {

  //home slider part
  .hero-section {
    display: flex;
    // flex-direction: column;

    .columns {
      display: flex;
      // flex-direction: column-reverse !important;
    }

    .column {
      .landing-page-left-container {
        padding: 5px !important;
        margin: 0 !important;

        .best-text {
          font-size: 1.2rem;
        }

        .home-slide-title {
          font-size: .9rem;
          font-family: $monoton-font !important;
      

        }

        .landing-page-sub-text {
          font-size: .7rem;
        }
      }
    }
  }

  // / About us 
  .about-us-container {
    padding: 10px !important;
  }

  // Service-page
  .service-page-section {
    padding: 10px !important;
  }

  .sds-sub-title {
    font-size: 1.5rem !important;
  }
}

.markqee-text {
  background-color: $primary-color !important;

  span {
    font-size: 2.5rem;
    margin-right: 1rem;
    font-family: $DMSans-font;
  }
}


// Sections
.about-us-section {
  background: url(../..//assets/images/Background/wave-1.jpg);

  // height: 60vh;

}

.service-page-section {
  background: url(../..//assets/images/Background/service-wave-bg.jpg);
  padding: 4rem 2rem;
}

.about-us-container {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(3px) !important;
  padding: 4rem 6rem;

  .aboutus-left-container {
    // background: url(../..//assets/images/shape-24.png);
  }

  .aboutus-right-container {}

  .title {
    font-size: 1.2rem;
    font-weight: 600;
    color: $primary-color;
    font-family: $DMSans-font;
    font-weight: 600;
  }

  .about-us-sub-title {
    font-size: 2.5rem;
    color: black;
    font-family: $roboto-font;
    font-weight: 900;
    margin-bottom: 2rem;

    span {
      color: $primary-color;
    }

  }

  .icon {
    background-color: $primary-color;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    transition: all 0.3s ease;
    box-shadow: 2px 2px 4px rgb(37, 5, 134);

    &:hover {
      background: darken(white, 10%);
    }

    &:hover i {
      color: $primary-color;
    }

    i {
      font-size: 2.5rem;
    }
  }
}

.about-section,
.services-section,
.blog-section,
.contact-section {
  padding: 80px 20px;
  background: white;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2rem;
    color: $secondary-color;
  }
}

// Services
.services-container {
  display: flex;
  justify-content: center;
  gap: 20px;

  .service-box {
    width: 250px;
    padding: 20px;
    background: lighten($primary-color, 40%);
    border-radius: 10px;
  }
}

// Blog Section
.blog-container {
  display: flex;
  justify-content: center;
  gap: 20px;

  .blog-box {
    width: 300px;
    padding: 20px;
    background: lighten($secondary-color, 40%);
    border-radius: 10px;
  }
}

// Contact Section
.contact-button {
  padding: 10px 20px;
  font-size: 1rem;
  background: $primary-color;
  color: white;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: darken($primary-color, 10%);
  }
}

//About Section
// Footer
.footer-section {
  background: $secondary-color;
  color: white;
  padding: 20px;
}

.sds-brand-slider {

  .marquee-container {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, .2);

  }

  .marquee {
    display: flex;
    gap: 20px;
  }

  .brand-card {
    width: 200px;
    background: white;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin: 10px;
    opacity: 0;
    transform: translateX(50px);
  }

  .brand-logo {
    width: 80px;
    height: auto;
    margin-bottom: 10px;
  }

}

.sds-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: $primary-color;
  font-family: $DMSans-font;
  font-weight: 600;
}

.sds-sub-title {
  font-size: 2.5rem;
  color: black;
  font-family: $roboto-font;
  font-weight: 900;
  margin-bottom: 2rem;

  span {
    color: $primary-color;
  }

}

.about-section {
  background: #1f2b38 !important;
}

.team-page-section {
  padding: 4rem 2rem;
}

.team-service-page-section {
  background: url(../../assets/images/Background/bird-feather.jpg);
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.scroll-to-top.show {
  opacity: 1;
  transform: translateY(0);
}

.scroll-to-top:hover {
  background: linear-gradient(135deg, #5a75f0, #925de4);
}

@media screen and (max-width: 768px) {
  .navbar-menu-mobile {
    width: 85%; // Increase menu width for smaller devices
  }
}

// Custom styles for About Us page
.about-us-page {
  // Hero section customization
  background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);
  .hero.is-primary {
    background: linear-gradient(135deg, $gradient-start 0%, $gradient-end 100%);
    
    .title, .subtitle {
      font-weight: 700;
    }
  }
  
  // Content box styling
  .content-box {
    padding: 2rem;
    height: 100%;
    border-radius: $border-radius;
    box-shadow: 2px 2px 2px 4px rgba(0, 0, 0, 0.05);
    background-color: $background-color;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 10px 20px $shadow-hover-color;
    }
    
    .title {
      margin-bottom: 1.5rem;
      color: $primary-color;
    }
  }
  
  // Core values card styling
  .value-card {
    padding: 2rem;
    height: 100%;
    border-radius: $border-radius;
    background-color: $background-color;
    box-shadow: 5px 4px 20px rgba(0, 0, 0, 0.05);
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease;
    
    &:hover {
      transform: translateY(-5px);
    }
    
    .value-number {
      position: absolute;
      top: 1rem;
      right: 1rem;
      font-size: 3rem;
      font-weight: 800;
      color: $value-number-color;
      line-height: 1;
    }
    
    .title {
      margin-bottom: 1rem;
      color: $primary-color;
    }
  }
  
  // Compliance card styling
  .compliance-card {
    padding: 2rem;
    height: 100%;
    border-radius: $border-radius;
    background-color: $background-color;
    box-shadow: 0 4px 10px $shadow-color;
    position: relative;
    transition: transform 0.3s ease;
    
    &:hover {
      transform: translateY(-5px);
    }
    
    .compliance-icon {
      margin-bottom: 1.5rem;
      font-size: 2.5rem;
      color: $primary-color;
    }
    
    .title {
      margin-bottom: 1rem;
      color: $primary-color;
    }
    
    ul {
      margin-left: 1.5rem;
      margin-bottom: 1.5rem;
      
      li {
        margin-bottom: 0.5rem;
      }
    }
  }
  
  // Team card styling
  .team-card {
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 10px 20px $shadow-hover-color;
    }
    
    .card-content {
      padding: 1.5rem;
      
      .title {
        margin-bottom: 0.5rem;
      }
      
      .subtitle {
        color: $primary-color;
      }
    }
  }
  
  // Contact CTA section
  .contact-cta {
    padding: 4rem 2rem;
    margin-top: 2rem;
    background-color: $accent-color;
    border-radius: $border-radius;
    
    .button.is-primary {
      background: linear-gradient(135deg, $gradient-start 0%, $gradient-end 100%);
      border: none;
      margin-top: 1rem;
      
      &:hover {
        background: linear-gradient(135deg, $gradient-hover-start 0%, $gradient-hover-end 100%);
      }
    }
    
    // Legal links styling
    .legal-links {
      font-size: 0.9rem;
      color: $text-light-color;
      
      a {
        text-decoration: underline;
        transition: color 0.2s ease;
        
        &:hover {
          color: $secondary-color;
        }
      }
    }
  }
}


/* TermsAndConditions.scss */
.terms-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem 1rem;
  // background-image: linear-gradient(to top, #dfe9f3 0%, white 100%);
  
  .terms-title {
    margin-bottom: 2rem;
    color: #363636;
    font-weight: 600;
    position: relative;
    
    &:after {
      content: '';
      display: block;
      width: 100px;
      height: 4px;
      background: #3273dc;
      margin: 1rem auto;
    }
  }
  
  .terms-effective-date {
    width: 100%;
    max-width: 400px;
    margin: 0 auto 2rem;
    background-color: #f5f5f5;
    border-left: 5px solid #3273dc;
  }
  
  .terms-content {
    line-height: 1.7;
    
    h2 {
      margin-top: 2rem;
      margin-bottom: 1rem;
      color: #363636;
      border-bottom: 1px solid #dbdbdb;
      padding-bottom: 0.5rem;
    }
    
    p {
      margin-bottom: 1.5rem;
    }
    
    ul, ol {
      margin-left: 2rem;
      margin-bottom: 1.5rem;
    }
  }
  
  .back-to-top {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 10;
    
    button {
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  }
}

/* PrivacyPolicy.scss */
.privacy-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem 1rem;
  
  .privacy-title {
    margin-bottom: 2rem;
    color: #363636;
    font-weight: 600;
    position: relative;
    
    &:after {
      content: '';
      display: block;
      width: 100px;
      height: 4px;
      background: #00d1b2;
      margin: 1rem auto;
    }
  }
  
  .privacy-effective-date {
    width: 100%;
    max-width: 400px;
    margin: 0 auto 2rem;
    background-color: #f5f5f5;
    border-left: 5px solid #00d1b2;
  }
  
  .privacy-content {
    line-height: 1.7;
    
    h2 {
      margin-top: 2rem;
      margin-bottom: 1rem;
      color: #363636;
      border-bottom: 1px solid #dbdbdb;
      padding-bottom: 0.5rem;
    }
    
    p {
      margin-bottom: 1.5rem;
    }
    
    ul, ol {
      margin-left: 2rem;
      margin-bottom: 1.5rem;
    }
  }
  
  .privacy-section {
    margin-bottom: 3rem;
    padding-bottom: 1rem;
  }
  
  .privacy-info-card {
    height: 100%;
    transition: all 0.3s ease;
    
    &:hover {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
      transform: translateY(-5px);
    }
  }
  
  .privacy-tile {
    transition: all 0.3s ease;
    height: 100%;
    
    .title {
      margin-bottom: 1rem;
    }
    
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }
  }
  
  .privacy-security-box, 
  .privacy-rights-card,
  .privacy-contact-box {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    transition: all 0.3s ease;
    
    &:hover {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }
  }
  
  .privacy-rights-card {
    border-left: 5px solid #00d1b2;
  }
  
  .privacy-contact-box {
    background-color: #f5f5f5;
    border-left: 5px solid #00d1b2;
    padding: 1.5rem;
  }
  
  .back-to-top {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 10;
    
    button {
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  }
}