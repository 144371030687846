@import 'variables';

*{
  font-family: $primary-font ;
}

/** site title **/
.site-title {}


.smart-kgr-table_main_container {
    .smart-table-head {
        div.column {
            display: flex;
            align-items: center;
        }
    }

    .smart-table-container {
        min-height: 300px;
        
        table {
          width: 100%;
          font-size: 1em;
          border-radius: 3px;
          overflow: hidden;
          border: 1px solid rgba(255, 255, 255, 0.2);
          background: rgba(255, 255, 255, 0.1);
          backdrop-filter: blur(10px);
      
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
          border-radius: 5px;
      
          thead {
            font-size: 14px;
            border-bottom: 2px solid white;
            background: $primary-color;
      
            tr {
              th {
                color: white;
                font-weight: bold;
              }
            }
          }
      
          tbody {
            tr {
              border-bottom: 1px solid rgba(255, 255, 255, 0.5);
              font-size: 14px;
              color: black;
              background: rgba($primary-color,.4);
              transition: background-color 0.3s ease, color 0.3s ease;
      
              td {
                padding: 5px 10px;
              }
      
              /* Icon styles */
              .smart-kgr-table-view-icon {
                font-size: 1rem;
                color: rgb(53, 255, 70) !important;
                cursor: pointer;
              }
      
              .smart-kgr-table-edit-icon {
                font-size: 1rem;
                color: #00dd !important;
                cursor: pointer;
              }
      
              .smart-kgr-table-delete-icon {
                font-size: 1rem;
                color: #ff1d58 !important;
                cursor: pointer;
              }
      
              /* Hover effect */
              &:hover {
                background: rgba(51, 92, 103, 0.5) !important; 
                color: black;
      
                .smart-kgr-table-view-icon {
                  color: rgb(53, 255, 70);
                }
      
                .smart-kgr-table-edit-icon {
                  color: rgb(0, 0, 255);
                }
      
                .smart-kgr-table-delete-icon {
                  color: #ff0447;
                }
              }
      
              &:nth-of-type(even) {
                background: $primary-color; 
                
                &:hover {
                  background: rgba(51, 92, 103, 0.9); 
                }
              }
      
             
              &:last-of-type {
                border-bottom: 2px solid rgba(255, 255, 255, 0.8);
              }
            }
          }
        }
      }
      
    .smart-table-pagination {
        .pagination-list {
            .pagination-link.is-current {
                background-color: var(--secondary-color)
            }
        }
    }

    .smart-table-footer {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 20px;

        .column {
            margin: 0;
            padding: 0 !important;
        }
    }

    .smart-table-head-buttons{
      .button{
        padding: 15px 30px;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    background-color: $primary-color;
    transition: background-color 0.3s ease;
    // font-size: 10px;
    .label{
       color: white;
    }

    /* Smooth transition */
    &:hover {
        background-color: darken($primary-color, 10%);
        color: white;
    }

    &:focus {
        color: white;
    }
      }
    }

}





/*** buttons for this site : Planned to have three styles of buttons ***/
.smart-action-button {
    padding: 15px 30px;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    background-color: $primary-color;
    transition: background-color 0.3s ease;

    /* Smooth transition */
    &:hover {
        background-color: darken($primary-color, 10%);
        color: white;
    }

    &:focus {
        color: white;
    }
}

/*** buttons for this site : Planned to have three styles of buttons ***/
.smart-third-button {
    padding: 15px 30px;
    text-align: center;
    color: $primary-color;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    background-color: white;
    border: 1px solid $primary-color;
    transition: background-color 0.3s ease;

    /* Smooth transition */
    &:hover {
        background-color: darken($primary-color, 10%);
        color: white;
    }

    &:focus {
        color: $primary-color;
    }
}

/*** sd efl modal ***/
.sd-efl-modal {
    .modal-card {
        border: 1px solid $primary-color;
        border-radius: 15px;

        .modal-card-body {
            min-height: 100px;
        }
    }

    .modal-card-title {
        color: $secondary-color
    }
}

// .smart-select{
//     border:1px solid #485fc7;
// }
.css-13cymwt-control {
    border: 1px solid $secondary-color !important;
}

.sd-pdf-form {
    .input {
        border-radius: 10px;
        border: 1px solid $primary-color;
    }

    .smart-select {
        border: none;
    }

    .css-13cymwt-control {
        border-radius: 10px;
        border: 1px solid $primary-color !important;
    }

    .textarea {
        border-radius: 10px;
        border: 1px solid $primary-color;
    }

    .label {
        color: $primary-color ;
        font-family: roboto;
        font-weight: 600;
    }
}

.smart-kgr-table_main_container {
    p {
        font-weight: bold !important;
        font-family: roboto;
    }

    .input {
        border-radius: 10px;
        border: 1px solid $primary-color;
    }
}

.pagination-link.is-current {
    border: 1px solid $primary-color;
    background-color: $secondary-color !important;
}

.sd-cursor {
    cursor: pointer;
}

.modal {
    transition: all 5s ease;
    z-index: 999 !important ;
}

.sd-pdf-horizontal-form {
    .is-horizontal {
        .control {
            flex-grow: 2;
        }
        .label{
            width:40%;
        }

      
    }
}
.text-area-border{
  textarea {
    border: none !important;
  }
  .is-success.textarea{
    border: none !important;
  }
}
.sd-hrms-input {
  .input {
    border-radius: 8px;
    border: 1.5px solid  $primary-color;
    font-family: Inter;
    &:disabled{
      background-color: #e6e6e6;
    }
  }
  
  .smart-select {
    border: none;
  }
  .css-13cymwt-control {
    border-radius: 8px;
    border: 1.5px solid $primary-color !important;
  }
  .textarea {
    border-radius: 8px;
    border: 1.5px solid $primary-color;
  }
  .label {
    color: black;
    font-family: roboto;
    font-size: 12px;
    font-family: Inter;
  }
}
.animated-cursor {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: fixed;
  pointer-events: none;
  z-index: 9999;
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.8);
  transition: background-color 0.2s ease-in-out;
}
.rainbow-trail {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: fixed;
  pointer-events: none;
  z-index: 9999;
  filter: blur(3px); /* Makes the cursor smooth */
  mix-blend-mode: difference; /* Cool color blending */
  transition: background-color 0.2s ease-in-out;
}
// .sds-brand-slide{
//   background: #1f2b38 !important;
// }

::-webkit-scrollbar {
  width: 5px; /* width of the scrollbar */
  height: 5px;
}

/* Scrollbar Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba($primary-color, 1); /* color of the scrollbar handle */
  border-radius: 5px; /* roundness of the scrollbar handle */
  width: 14px;
}

/* Scrollbar Track when hovering */
::-webkit-scrollbar-track:hover {
  background-color: #f0f0f0; /* color of the scrollbar track when hovering */
}
