@import "../../assets/scss/variables.scss";
.login-page {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-image: linear-gradient(to top, #fff1eb 0%, #ace0f9 100%);

  // .background-overlay {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: rgba(0, 0, 0, 0.3);
    
  //   .background-image {
  //     width: 100%;
  //     height: 100%;
  //     object-fit: cover;
  //     filter: blur(2px);
  //   }
  // }

  .login-container {
    position: relative;
     width: 400px;
    background: rgba(255, 255, 255, 0.062);
    backdrop-filter: blur(10px);
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    text-align: center;
    .input{
      border-radius: 20px !important;
      background-color:rgba(255, 255, 255, 0.15);
      color: black;

      
    }
    .label{
      background: rgba(255, 255, 255, 0.05);
      color: black;
      font-weight: bold;
    }
  }

  .logo-container {
    margin-bottom: 1rem;
    
    .logo {
      max-width: 150px;
    }
  }

  .forgot-password {
    margin-top: 10px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
  }

  .login-button {
    .button{

    
    width: 100%;
    padding: 12px;
    background: $primary-color;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border-radius: 20px;
    cursor: pointer;
    transition: background 0.3s;

  }
   
  }

  .signup-link {
    margin-top: 15px;
    font-size: 14px;
    color: black;
    cursor: pointer;
    text-decoration: underline;
  }
}
.smart-login-back-button{
  background-color: white;
  border-radius: 5px ;
  padding: 9px 25px;
  color: black;
  font-size: 15px;
  font-weight: 300 !important;
  transition: background-color 0.3s ease;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  font-family: DMSans;
  &:hover {
    background-color: $primary-color;
    color: white;
  }
}
