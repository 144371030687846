.gallerySelection {
  .gallery-item {
    overflow: hidden;
    border-radius: 8px !important;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    -webkit-animation: tilt-in-top-2 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: tilt-in-top-2 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);

      img {
        opacity: 0.8;
        transform: scale(1.1);
      }
    }

    .card-image {
      position: relative;

      img {
        transition: all 0.4s ease-in-out;
      }
    }
  }
  @-webkit-keyframes tilt-in-top-2 {
    0% {
      -webkit-transform: rotateY(-30deg) translateY(-300px) skewY(30deg);
      transform: rotateY(-30deg) translateY(-300px) skewY(30deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
      transform: rotateY(0deg) translateY(0) skewY(0deg);
      opacity: 1;
    }
  }
  @keyframes tilt-in-top-2 {
    0% {
      -webkit-transform: rotateY(-30deg) translateY(-300px) skewY(30deg);
      transform: rotateY(-30deg) translateY(-300px) skewY(30deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
      transform: rotateY(0deg) translateY(0) skewY(0deg);
      opacity: 1;
    }
  }
}

.slider-images {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
