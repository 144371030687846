
.tabs-container {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
  }
  
  .tab-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1rem;
    cursor: pointer;
    background: linear-gradient(to right, rgba(51, 92, 103, 0.1), rgba(145, 235, 245, 0.1));

    font-weight: bold;
    font-size: 1rem;
    border-radius: 10px 30px 0 0;
    border: 1px solid white;
    transition: transform 0.3s, background-color 0.3s;
  
    .tab-icon {
      margin-right: 0.5rem;
    }
  
    &:hover {
      transform: translateY(-5px);
   
    }
  
    &.active {
      background: linear-gradient(135deg, #335C67, #9FB4C7);

      color: #fff;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  
      &::after {
        content: "";
        position: absolute;
        bottom: -3px;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
        height: 3px;
        // background-color: blue;
      }
    }
  }
  
  .settings-content {
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    min-height: 80vh;
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease-in-out;
    .textarea{
      border-color: #ffffff
    }
  }
  

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  