.smart-dash-card {
  border-radius: 12px;
  padding: 10px;
  position: relative;
  border: 2px solid #c6c4c433;
  background: linear-gradient(
    145deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0.1)
  );
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2),
    inset 0 0 10px rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  cursor: pointer;
  -webkit-animation: tilt-in-top-1 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: tilt-in-top-1 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  .icon {
    position: absolute;
    bottom: 48%;
    right: 20%;
    i {
      font-size: 4rem;
      color: #ffffff;
    }
  }
  .card-title {
    font-size: 1rem;
    font-family: Inter;
    color: black;
    font-weight: bold;
    margin-bottom: 5px;
  }
  &:hover {
    transform: scale(1.02);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  }
}
@-webkit-keyframes tilt-in-top-1 {
  0% {
    -webkit-transform: rotateY(30deg) translateY(-300px) skewY(-30deg);
    transform: rotateY(30deg) translateY(-300px) skewY(-30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
    transform: rotateY(0deg) translateY(0) skewY(0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-top-1 {
  0% {
    -webkit-transform: rotateY(30deg) translateY(-300px) skewY(-30deg);
    transform: rotateY(30deg) translateY(-300px) skewY(-30deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0deg) translateY(0) skewY(0deg);
    transform: rotateY(0deg) translateY(0) skewY(0deg);
    opacity: 1;
  }
}

.smart-card-bg-one {
  background-image: linear-gradient(to right, #f77a7a 0%, #ff0844 100%);
}
.smart-card-bg-two {
  // background: linear-gradient(145deg, #74c0fc, #4dabf7);background-image: linear-gradient(to right, #92fe9d 0%, #00c9ff 100%);
  background-image: linear-gradient(to right, #92fe9d 0%, #00c9ff 100%);
}
.smart-card-bg-three {
  background-image: linear-gradient(to right, #0acffe 0%, #495aff 100%);
}
.smart-card-bg-four {
  background: linear-gradient(to right, #ffa94d 0%, #ff922b 100%);
}
.appointment-card {
  .card {
    width: 30% !important;
    margin: 10px;
  }
}

.list-container-items {
  /* Container for the list */
  background-color: rgba(255, 255, 255, 0.1);
  .list-container {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  /* Individual list items */
  .list-item {
    padding: 10px 20px;
    margin: 10px 0;
    border-radius: 8px;
    font-size: 16px;
    color: black;
    background-color: white; /* Bulma primary color */
    animation: fadeIn 0.5s ease-in-out;
    transition: transform 0.3s, background-color 0.3s;
  }

  /* Hover effect for list items */
  .list-item:hover {
    transform: scale(1.03);
    background-color: #276cda; /* Darker primary color */
  }

  /* Fade-in animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
.smart-text-area{
  .textarea:not([rows]){
    min-height: 20rem !important;
  }
  .is-success.textarea{
  border-color: none !important;
  border: none !important;
}
}