.custom-upgrade-box {
  max-width: 500px;
  margin: 20px auto;
  padding: 25px;
  text-align: center;
  border: 2px solid #ff3860;
  border-radius: 8px;
  background-color: #fff5f5;
}

.icon-lock {
  color: #ff3860;
  margin-right: 5px;
}

.icon-feature {
  color: #3273dc;
  margin-right: 8px;
}

.features-list {
  list-style: none;
  padding-left: 0;
}

.features-list li {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 5px;
}



/* ContactUs.scss */
.contact-section-page {
  background-color: #f8f9fa;
  
  .contact-title {
    position: relative;
    display: inline-block;
    margin-bottom: 2rem;
    
    &:after {
      content: '';
      display: block;
      width: 100px;
      height: 4px;
      background: linear-gradient(90deg, #00d1b2, #3273dc);
      margin: 1rem auto 0;
      border-radius: 2px;
    }
  }
  
  .contact-info-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .contact-image-container {
    flex: 0 0 200px;
    margin-bottom: 1.5rem;
    
    .contact-image {
      max-width: 100%;
      height: auto;
      border-radius: 8px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    }
  }
  
  .contact-info-container {
    background-color: white;
    padding: 2px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    flex: 1;
    
    &:hover {
      box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
      transform: translateY(-5px);
    }
  }
  
  .contact-info-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.25rem;
    
    .icon {
      margin-right: 1rem;
      margin-top: 0.25rem;
    }
  }
  
  .social-icons {
    display: flex;
    gap: 1rem;
    
    .social-icon {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #f5f5f5;
      transition: all 0.3s ease;
      
      &:hover {
        background-color: #eeeeee;
      }
    }
  }
  
  .contact-form-box {
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    border-top: 5px solid #00d1b2;
    
    &:hover {
      box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
    }
    
    .field {
      margin-bottom: 1.5rem;
    }
    
    .button {
      font-weight: 600;
      height: 3rem;
    }
  }
  
  // Responsive adjustments
  @media screen and (max-width: 768px) {
    .contact-image-container {
      flex: 0 0 150px;
    }
    
    .contact-info-container {
      margin-bottom: 2rem;
    }
  }
  
  // Animation for the section when scrolling
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .animate-fade-in-up {
    animation: fadeInUp 0.6s ease-out forwards;
  }
}

.smart-header-intro {
  text-align: center;
  // max-width: 800px;
  margin-bottom: 2px;
  background: linear-gradient(135deg, #3498db, #8e44ad);
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);

  h2 {
    font-size: 26px;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    line-height: 1.6;
  }
}