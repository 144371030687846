@import "../../assets/scss/variables.scss";
.team-card {
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  // width: 250px;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
  }

  .image-container {
    position: relative;
    overflow: hidden;
    border-radius: 15px 15px 0 0;
    height: 250px;

    .profile-image {
      width: 100%;
      height: auto;
      height: 250px;
      border-radius: 15px 15px 0 0;
      object-fit: fill;
      cursor: pointer;
      transition: transform 0.3s ease-in-out;
      &:hover {
        transform: scale(1.05);
      }
      img {
        width: 100%;
        height: 100%;
        // object-fit: cover;
      }
    }

    .social-icons {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      flex-direction: column;
      gap: 10px;

      .icon {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 16px;
        color: #fff;
        font-weight: bold;
        text-decoration: none;
        transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;

        &.linkedin {
          background: #0077b5;
        }
        &.dribbble {
          background: #ea4c89;
        }
        &.facebook {
          background: #3b5998;
        }

        &:hover {
          transform: scale(1.1);
          opacity: 0.8;
        }
      }
    }
  }

  .info {
    padding: 15px;

    .role {
      font-size: 14px;
      color: gray;
      margin-bottom: 5px;
    }

    .name {
      font-size: 18px;
      font-weight: bold;
    }
  }
}

//ChoseUs
.choseUs-container {
  background: hsla(218, 74%, 32%, 1);

  background: radial-gradient(
    circle,
    hsla(218, 74%, 32%, 1) 32%,
    hsla(211, 29%, 17%, 1) 100%
  );

  background: -moz-radial-gradient(
    circle,
    hsla(218, 74%, 32%, 1) 32%,
    hsla(211, 29%, 17%, 1) 100%
  );

  background: -webkit-radial-gradient(
    circle,
    hsla(218, 74%, 32%, 1) 32%,
    hsla(211, 29%, 17%, 1) 100%
  );
  position: relative;

  .sub-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 400 !important;
    height: 250px !important;
    img {
      //  width: 400 !important;
      //  height: 250px;
    }
  }
  .brand-us-text {
    font-size: 3rem;
    // z-index: 1000;
    position: relative;
    color: #fff;
    margin-left: 50px;
    font-weight: 800;
    font-family: $DMSans-font;
  }
  .brand-container {
    overflow: hidden;
    position: relative;
    background-color: #273545;
    // padding: 20px;

    border-radius: 15px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
  }
  .feature-cards-container {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    // background: #1e293b;
    // padding: 40px;
  }

  .feature-card {
    display: flex;
    align-items: center;
    // background: #1e293b;
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    gap: 15px;
    transition: all 0.3s ease-in-out;
    max-width: 400px;

    &:hover {
      transform: scale(1.05);
    }

    .feature-card-icon {
      font-size: 24px;
      background: #2d3748;
      padding: 10px;
      border-radius: 50%;
    }

    .feature-card-content {
      .title {
        font-size: 18px;
        font-weight: bold;
      }

      .description {
        font-size: 14px;
        opacity: 0.8;
      }
    }
  }

  @media (max-width: 768px) {
    .feature-cards-container {
      flex-direction: column;
      align-items: center;
    }
  }
}
.process-section {
  background-color: #1e2836;
  padding: 4rem 0;
  text-align: center;

  .process-card {
    position: relative;
    background-color: #232c3b;
    color: white;
    border-radius: 12px;
    padding: 2rem;
    text-align: left;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    }

    .process-number {
      position: absolute;
      top: -20px;
      right: 20px;
      background: linear-gradient(45deg, #5029d6, #1d9bf0);
      color: white;
      padding: 8px 12px;
      border-radius: 50%;
      font-weight: bold;
      font-size: 14px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    }
  }

  .title {
    font-weight: 700;
  }
}
.staff-section {
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  // padding: 4rem 0;

  .staff-card {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    border-left: 4px solid $primary-color;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    }

    .staff-icon {
      font-size: 1.2rem;
      margin-right: 5px;
    }

    .staff-title {
      font-size: 1rem;
      font-weight: 600;
      color: #273c75;
    }
  }
}

.stat-card {
  text-align: center;
  border-radius: 20px;
  padding: 2rem;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }

  .stat-value {
    font-size: 2.5rem;
    font-weight: 700;
    color: #000;
  }

  .stat-label {
    font-size: 1rem;
    font-weight: 500;
    color: #555;
    margin-top: 0.5rem;
  }
}

// Make it responsive
@media screen and (max-width: 768px) {
  .stat-card {
    padding: 1.5rem;
    .stat-value {
      font-size: 2rem;
    }
    .stat-label {
      font-size: 0.9rem;
    }
  }
}

.contact-container {
  // display: flex;
  // flex-wrap: wrap;
  gap: 30px;
  // padding: 40px;
  // align-items: center;
  // justify-content: center;
  padding: 2rem;

  .contact-info {
    background: $primary-color;
    color: white;
    padding: 30px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 2rem;
    // max-width: 400px;

    .info-box {
      display: flex;
      align-items: center;
      gap: 15px;
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);

      i {
        font-size: 20px;
      }

      h4 {
        font-size: 16px;
        margin: 0;
      }

      p {
        margin: 0;
        font-size: 14px;
        font-weight: bold;
      }
    }

    .contact-image {
      position: relative;
      img {
        width: 100%;
        border-radius: 8px;
      }

      .play-button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: white;
        color: $primary-color;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
  /// Contact
  .contact-form {
    max-width: 500px;

    h2 {
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 10px;
    }

    // p {
    //   color: #6b7280;
    //   font-size: 14px;
    //   margin-bottom: 20px;
    // }

    .form-group {
      display: flex;
      gap: 10px;
      margin-bottom: 15px;

      .input {
        flex: 1;
        padding: 10px;
        border: 1px solid #d1d5db;
        border-radius: 5px;
      }
    }

    .textarea {
      width: 100%;
      padding: 10px;
      border: 1px solid #d1d5db;
      border-radius: 5px;
      min-height: 100px;
      margin-bottom: 15px;
    }

    button {
      background: $primary-color;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        background: #2563eb;
      }
    }
  }
}

@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
  }
}

.cookieConsent {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: #e89d07;
  padding: 15px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  font-size: 14px;

  a {
    color: #ffcc00;
    text-decoration: none;
  }

  button {
    background: #ffcc00;
    color: #000;
    padding: 8px 16px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
    transition: 0.3s;

    &:hover {
      background: #e6b800;
    }
  }
}

// technologiesSection

.technologiesSection {
  text-align: center;
  padding: 50px 20px;
  background-color: #f8f9fa;

  .title {
    font-size: 1.5rem;
    font-weight: bold;
    color: $primary-color;
  }

  .heading {
    font-size: 3rem;
    font-weight: 800;
    // color: $primary-color;
    margin-bottom: 30px;
    font-family: $monoton-font !important;
  }

  .slider-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    padding: 20px 0;
  }

  .slider {
    display: flex;
    gap: 50px;
    white-space: nowrap;
  }

  .techItem {
    flex: 0 0 auto;
    width: 150px;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    object-fit: contain;

    img {
      width: 100%;
      max-width: 120px;
      height: auto;
    }
  }
}

// services-section
.team-service-page-section {
  // background: url(../../assets/images/Background/bird-feather.jpg);
  background-image: linear-gradient(
    -225deg,
    #e3fdf5 0%,
    #ffe6fa 100%
  ) !important;
}
.sds-services-section {
  text-align: center;
  padding: 4rem 2rem;
  backdrop-filter: blur(10px);

  .section-title {
    font-size: 2.5rem;
    font-weight: 700;
    color: #222;
    margin-bottom: 0.5rem;
  }

  .section-subtitle {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 2rem;
  }

  .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    justify-content: center;
  }

  .service-card {
    position: relative;
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);

      .service-description {
        display: block;
        opacity: 1;
        transform: translateX(0);
      }
    }

    .icon-container {
      font-size: 2rem;
      color: #007bff;
      margin-bottom: 1rem;
    }

    .service-title {
      font-size: 1.4rem;
      font-weight: bold;
      color: #333;
    }

    .service-description {
      font-size: 1rem;
      color: #555;
      display: none;
      opacity: 0;
      transform: translateX(-10px);
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    }

    .gradient-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        135deg,
        rgba(0, 123, 255, 0.1),
        rgba(255, 255, 255, 0.5)
      );
      opacity: 0;
      transition: opacity 0.3s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }
}

// Make cards equal height
.csp-card {
  height: 100%;
  display: flex;
  flex-direction: column;

  .card-content {
    flex-grow: 1;
  }

  // Add hover effect
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(10, 10, 10, 0.1);
  }
}

// Icon styling
.csp-icon-primary {
  color: #3273dc;
}

// Supporting point styling
.csp-supporting-point {
  margin-bottom: 0.75rem;

  &:last-child {
    margin-bottom: 0;
  }
}

// Supporting icon sizing
.csp-supporting-icon {
  font-size: 1rem;
}

// Title enhancement
.csp-title {
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 80px;
    height: 3px;
    background-color: #3273dc;
    margin: 1rem auto 0;
  }
}
