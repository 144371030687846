@import "../../assets/scss/index.scss";
@import "../../assets/scss/variables.scss";

.smart-efl-container {
  
  background: $body-dark-background-image;
  .efl-main-header {
    .navbar {
      background-color: rgba(0, 0, 0, 0.6) !important;
      color: $white-color;
     
    }
  }

  .efl-main-div {
    background-color: rgba(0, 0, 0, 0.5) !important;
  }

  /*** side nav styles ***/
  // Base sidenav styles
  .smart-sidenav {
    background-color: rgba(247, 242, 242, 0.6) !important;

   

    .brand-logo {
      background-color: rgb(0, 0, 1);
    }

    .sidenav-link {
      color: white;

      &:hover {
        background-color: rgba(0, 0, 0, 0.8);
        color: rgb(171, 30, 83);
        border-top: 1px solid rgb(171, 30, 83);
        font-weight: bold;
      }

      &.active-link {
        background-color: rgba(0, 0, 0, 0.8);
        color: rgb(171, 30, 83);
        font-weight: bold;
      }
    }
  }

  .card {
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2) !important;
    border: 0.5px solid rgba(255, 255, 255, 0.2) !important;
    background-color: rgba(0, 0, 0, 0.2) !important;
    color: white !important;
  }

  .smart-table-head {
    p {
      color: white !important;
    }
  }

  .sd_efl-calender {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .calendar-header {
    background-color: rgba(0, 0, 0, 0.1) !important;
    color: $white-color;

    .dropdown {
      .button {
        color: $white-color !important;
      }
    }
  }

  .scrollable-table {
    .table {
      background-color: rgba(0, 0, 0, 0.4);
      backdrop-filter: blur(5px);

      th {
        color: white;
      }

      tr td {
        color: $white-color;
      }
    }
  }

  .smart-table-head-buttons {
    .icon {
      color: $white-color;
    }
  }

  .smart-kgr-table_main_container {
    .smart-table-container {
      table {
        /* Apply the frosted glass effect */
        border: 1px solid rgba(255, 255, 255, 0.2);
        background: rgba(
          255,
          255,
          255,
          0.1
        ); 
        backdrop-filter: blur(10px); // Blur effect for the glass look

        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        // margin-bottom: 20px;

        thead {
          // background: var(--secondary-color);
          font-size: 14px;
          border-bottom: 2px solid rgba(0, 0, 0, 0.8);
          background: rgba(255, 255, 255, 0.15);

          tr {
            th {
              color: var(--white-color);
              font-weight: bold;

              color: #fff;
            }
          }
        }

        tbody {
          tr {
            border-bottom: 1px solid rgba(255, 255, 255, 0.5);

            color: white;
            background: rgba(255, 255, 255, 0.05);
          

            // border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            // background-color: rgb(225, 243, 232);
            td {
              padding: 10px 10px;
            }

            &:hover {
              //  background-color: #3dca88 !important;
              background: rgba(0, 0, 0, 0.5) !important;
              // color: white !important;
              color: $white-color;
            }

            &:nth-of-type(even) {
              // background-color: rgb(248, 248, 248);
              background: rgba(255, 255, 255, 0.05) !important;
              backdrop-filter: blur(5px);

              &:hover {
                background: rgba(0, 0, 0, 0.5) !important;
              }
            }

            &:last-of-type {
              border-bottom: 2px solid rgba(255, 255, 255, 0.8);
            }
          }
        }
      }
    }
    .smart-table-footer {
      .smart-select {
        .smart-select-input {
          div {
            background-color: rgba(255, 255, 255, 0.05);
            color: $white-color;
            border: 1px solid rgba(255, 255, 255, 0.5);
          }
        }
      }
    }
  }
  .has-text-white {
    color: white !important;
  }

  //// end
}
