@import "../../assets/scss/variables.scss";
.card-container {
  perspective: 1000px;
}

.flip-card {
  width: 300px;
  height: 400px;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  position: relative;

  &:hover {
    transform: rotateY(180deg);
  }
}

.card-face {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.card-front {
  background-size: cover;
  background-position: center;
}

.card-back {
  background-color: #f5f5f5;
  color: #363636;
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  border: 2px solid #ffd700;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .flip-card {
    width: 250px;
    height: 350px;
  }
}

.smart-header-sds {
  background-color: $primary-color;
  padding: 1rem; max-height: 4.2rem;
  .smart-header-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
  }
  .smart-sds-headed {
    display: "flex";
    justify-content: "space-between";
    flex: 1;
  }
  .smart-header-content {
    display: flex;
    align-items: center;
    gap: 1 rem;
    color: #fff;
    a {
      color: #fff;
    }
    a:hover {
      color: aqua;
    }
    a.navbar-item:hover {
      background-color: transparent;
    }
  }
}
.smart-arco-footer{
  max-height: 5rem;
}