// @import "../../../assets/scss/variables.scss";
@import"../../../assets/scss/variables.scss";

.csp-main-container{
  background-image: linear-gradient(-225deg, #E3FDF5 0%, #FFE6FA 100%);
}

.projects-list-container {
    padding: 2rem 0;
    background-image: url(../../../assets/images/Background/ai-code.jpg);
    // background: #f9f9f9;
    background-size: cover;
    background-position: center;
 background-attachment: fixed;
    .projects-list-item {
      margin-bottom: 2rem;
      padding: 2rem;
      border-radius: 12px;
      background: rgba(255, 255, 255, 0.7);
      backdrop-filter: blur(10px);
    //   box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease-in-out;
      
      &:hover {
        transform: translateY(-5px);
      }
    }
  
    .projects-list-image-container {
      display: flex;
      justify-content: center;
      align-items: center;
  
      .projects-list-image {
        max-width: 100%;
        border-radius: 8px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
      }
    }
  
    .projects-list-content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1.5rem;
    }
  
    .projects-list-title {
      font-size: 2rem;
      font-weight: 700;
      color: #333;
      margin-bottom: .5rem;
    }
  
    .projects-list-subtitle {
      font-size: 1.2rem;
      color: #666;
      margin-bottom: 1rem;
    }
  
    .projects-list-list {
      list-style: none;
      padding-left: 0;
    }
  
    .projects-list-list-item {
      font-size: 1rem;
      color: #444;
      margin-bottom: 0.5rem;
      padding-left: 1.5rem;
      position: relative;
  
      &:before {
        content: '\2022';
        color: #007bff;
        font-weight: bold;
        position: absolute;
        left: 0;
      }
    }
  }
  
  .fixed-background {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    // height: 100vh;
    background-image: url(../../../assets/images/Background/ai-code.jpg);
    background-size: cover;
    background-position: center;
    background-attachment: fixed; /* This keeps the background fixed */
    z-index: -1; /* Sends it behind other content */
  }
 
  
  /* Introduction Section */
  .csp-intro {
    text-align: center;
    // max-width: 800px;
    margin-bottom: 20px;
    background: linear-gradient(135deg, #3498db, #8e44ad);
    color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  
    h2 {
      font-size: 26px;
      margin-bottom: 10px;
    }
  
    p {
      font-size: 16px;
      line-height: 1.6;
    }
  }
  
  .csp-summary {
    background: rgba(255, 255, 255, 0.1);
    padding: 15px;
    border-radius: 8px;
    margin-top: 15px;
    text-align: left;
  
    h3 {
      font-size: 18px;
      margin-bottom: 8px;
    }
  
    ul {
      list-style: none;
      padding: 0;
  
      li {
        margin: 5px 0;
        font-size: 14px;
      }
    }
  }
  
  /* Table Styles */
  .csp-table {
    width: 100%;
    border-collapse: collapse;
    background: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  
    th, td {
      padding: 12px 16px;
      text-align: left;
      border-bottom: 1px solid #ddd;
    }
  
    th {
      background: #3498db;
      color: #fff;
      font-size: 18px;
    }
  
    td {
      font-size: 16px;
    }
  }
  
  /* CSP List */
  .csp-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .csp-item {
    margin: 8px 0;
    border-radius: 5px;
    overflow: hidden;
  }
  
  /* Directive Title */
  .csp-title {
    background: #fefffe;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, .5),2px 2px 5px rgba(0, 0, 0, .5),;
    color: black;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
  
    &:hover {
      background: #bbffd7;
    }
  }
  
  .arrow {
    font-size: 18px;
  }
  
  /* Expand/Collapse Values */
  .csp-values {
    padding-left: 16px;
    margin-top: 5px;
    overflow: hidden;
  }
  
  .csp-value {
    background: #f4f4f4;
    padding: 8px;
    margin: 4px 0;
    border-radius: 3px;
    font-size: 14px;
  }
  .csp-input{
    padding: 10px 40px;
    background-color: transparent;
    position: relative;
    .input {
      border: none; // Remove border
      border-radius: 40px;
      background-color: rgba(255, 255, 255, .1) !important;
      // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); // Even shadow on all sides
      // padding: 20px 15px;
      outline: none;
      -webkit-box-shadow: -3px 6px 26px 7px rgba(0,0,0,0.16);
      -moz-box-shadow: -3px 6px 26px 7px rgba(0,0,0,0.16);
      box-shadow: -3px 6px 26px 7px rgba(0,0,0,0.16);
       transition: box-shadow 0.3s ease-in-out; 
       font-size: 1.5rem;
    

    
      &:focus {
        box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2); // More prominent shadow on focus
      }
      .control.has-icons-left .icon, .control.has-icons-right .icon{
        top: 22px !important;
      }
    }
    .smart-action-button{
      padding: 10px 15px;
      background-color: white;
      color: $primary-color;
      border: none;
      border-radius: 20px;
      box-shadow: -3px 6px 26px 7px rgba(0,0,0,0.16);
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;
      &:hover {
        // background-color: darken($primary-color, 10%);
      }
    }
    .csp-input-button {
       position: absolute;
     right: 5%;
        top: 5%;
      //  left: 80%;
      // margin-top: 8px;
    
      @media (max-width: 768px) {
        // top: 100px !important;
        position: relative !important;
        text-align: center;
      }
    }
  }



  // Define variables
  $primary-color: #3273dc; // Bulma primary color
  $success-color: #23d160;
  $warning-color: #ffdd57;
  $danger-color: #ff3860;
  
  // Styling for security sections
  .secure-content,
  .medium-content,
  .restricted-content {
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .secure-content {
    background-color: $success-color;
    color: white;
  }
  
  .medium-content {
    background-color: $warning-color;
    color: black;
  }
  
  .restricted-content {
    background-color: $danger-color;
    color: white;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  button {
    background-color: $primary-color;
    color: white;
    font-size: 18px;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
  
    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }
  .pie-chart-card{
    height: 150px;
   width: 150px;
    border-radius: 10px;
    // box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    // overflow: hidden;
    // position: relative;
  }
  
  .directive-card {
    // display: flex;
    // align-items: center;
    // gap: 10px;
    background: white;
    border-radius: 12px;
    padding: 11px 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-left: 4px solid #1e3a8a;
    transition: transform 0.3s ease-in-out;
    // cursor: pointer;
    display: flex;
    justify-content: space-between;
    
  
    &:hover {
      transform: translateY(-3px);
    }
  
    .arrow {
      // font-size: 20px;
      text-align: end;
      // background-color: $primary-color;
      border-radius: 50%;
      height: 30px !important;
      width: 30px !important;
        padding: 1px 6px;
      background-color: #09d06a;
      i{
        color: white;
        font-size: 15px;
      }
    }
  
    .directive-title {
      font-weight: bold;
      color: #1e3a8a;
      font-size: 13px;

    }
  }
  .smart-csp-button{
    position: absolute;
    left:0
  }

  @media (max-width: 768px) {
    .csp-input{
      padding: 5px;
      .csp-input-button{
        top: 0%;
        right: 0%;
        font-size: 10px;
        .smart-action-button{
          font-size: 12px !important;
        }
      }
    } 
   
  }


  .rate-card-container {
    padding: 50px 0;
    text-align: center;
  
    .card {
      border-radius: 12px;
      overflow: hidden;
      transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
      cursor: pointer;
  
      &:hover {
        transform: translateY(-10px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
      }
  
      .card-content {
        padding: 25px;
        text-align: center;
      }
  
      .title {
        font-size: 1.8rem;
        font-weight: bold;
      }
  
      .price {
        font-size: 1.5rem;
        color: #ff4757;
        font-weight: bold;
        margin-bottom: 10px;
      }
  
      ul {
        list-style: none;
        padding: 0;
        margin-top: 15px;
  
        li {
          background: #f8f9fa;
          padding: 8px;
          border-radius: 5px;
          margin-bottom: 5px;
        }
      }
  
      .button {
        width: 100%;
        font-weight: bold;
      }
    }
  
    .basic {
      background: linear-gradient(135deg, #f6d365, #fda085);
    }
  
    .pro {
      background: linear-gradient(135deg, #00c6ff, #0072ff);
      color: white;
  
      ul li {
        background: rgba(255, 255, 255, 0.2);
        color: white;
      }
    }
  
    .business {
      background: linear-gradient(135deg, #f99f8d, #fe7cb7);
      color: white;
  
      ul li {
        background: rgba(255, 255, 255, 0.2);
        color: white;
      }
    }
  
    .modal-card {
      .modal-card-title {
        color: #ff4757;
      }
    }
  }
  .csp-card-container {
    display: flex;
    justify-content: space-around;
    gap: 10px;
    // margin-top: 20px;
    
    .csp-card {
      width: 200px;
      min-height: 80px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      font-weight: bold;
      color: #000;
      font-size: 18px;
      box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
      margin-bottom: 10px;
  
      h4 {
        margin: 0;
        font-size: 16px;
      }
      
      p {
        margin: 5px 0 0;
        font-size: 22px;
      }
    }
  
    .high {
      background-color: #f8d7da; 
    }
  
    .medium {
      background-color: #fff3cd; 
    }
  
    .low {
      background-color: #e9ecef; 
    }
  
    .info {
      background-color: #d1ecf1; 
    }
  }
  @keyframes glow {
    0% {
      box-shadow: 0px 0px 10px rgba(0, 123, 255, 0.3);
    }
    50% {
      box-shadow: 0px 0px 20px rgba(0, 123, 255, 0.6);
    }
    100% {
      box-shadow: 0px 0px 10px rgba(0, 123, 255, 0.3);
    }
  }
  
  .smart-csp-score-card {
    height: auto;
    width: auto;
    background: white;
    border-radius: 10px;
    padding: 20px;
    position: relative;
    transition: all 0.3s ease-in-out;
    animation: glow 2s infinite alternate ease-in-out;
    
    &:hover {
      animation: glow 1s infinite alternate ease-in-out;
    }
  
  }
  
