/* variables.scss */
$primary-color:#219df7; 
//$primary-color: #4b74d2;

$secondary-color: #9848f5;
 $header-color: #af00c4;
 //$header-color: #31bc79;


$third-color: #3b3b3b;
$text-color: #ffffff;
$text-light-color: #666666;
$background-color: #ffffff;
$font-size: 16px;
$link-color: #007bff;
$link-hover-color: #0056b3;
$edit_button_color: #1130df;
$body-dark-background-image: url(./../images/Background/glass-bg.png);
$body-background-image: url(../images/Back.jpeg);
$primary-font: liber;
$roboto-font: roboto;
$inter-font: Inter;
$baumans-font: Baumans;
$alex-font: AlexBrush;
$DMSans-font: DMSans;
$monoton-font:monoton;
$accent-color: #f9f9f9;