// pricing-styles.scss
@import "../../../assets/scss/variables.scss";// pricing-styles.scss


$white: #ffffff;
$light-gray: #f5f5f5;
$medium-gray: #dbdbdb;
$dark-gray: #4a4a4a;
$success: #23d160;
$danger: #ff3860;

// Toggle switch styles
.billing-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  
  span {
    color: $dark-gray;
    transition: color 0.3s ease;
    
    &.is-active {
      color: $primary-color;
      font-weight: bold;
    }
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    
    input {
      opacity: 0;
      width: 0;
      height: 0;
      
      &:checked + .slider {
        background-color: $primary-color;
      }
      
      &:checked + .slider:before {
        transform: translateX(26px);
      }
    }
    
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $medium-gray;
      transition: .4s;
      
      &.round {
        border-radius: 34px;
        
        &:before {
          border-radius: 50%;
        }
      }
      
      &:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: $white;
        transition: .4s;
      }
    }
  }
}

// Custom styling for pricing cards
.pricing-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  // background-color: red;
  background-color: #d8ebff;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  }



  &.is-highlighted {
    border: 2px solid $primary-color;
    transform: scale(1.05);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 1;

    &:before {
      content: 'Most Popular';
      position: absolute;
      top: 0;
      right: 0;
      background: $primary-color;
      color: $white;
      padding: 0.5rem 1rem;
      font-size: 0.8rem;
      font-weight: bold;
      border-bottom-left-radius: 8px;
    }

    &:hover {
      transform: translateY(-5px) scale(1.05);
    }
  }

  .card-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .pricing-cost {
    margin: 1.5rem 0;
    line-height: 1;
    color: $dark-gray;
  }

  .pricing-features {
    margin: 1.5rem 0;
    flex-grow: 1;
  }

  .pricing-feature-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.75rem;
    color: $dark-gray;
    
    .icon {
      margin-right: 0.5rem;
      min-width: 20px;
      color: $success;
    }
  }
}

// Registration form styles
.registration-section {
  .plan-summary {
    background-color: $light-gray;
    
    hr {
      background-color: $medium-gray;
      margin: 1rem 0;
    }
    
    .level {
      margin-bottom: 0.5rem;
    }
    
    .has-text-danger {
      color: $danger;
    }
  }
  
  .label {
    color: $dark-gray;
  }
  
  .input {
    &:focus {
      border-color: $primary-color;
    }
  }
}

// Custom styling for comparison table
.comparison-table {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  overflow: hidden;

  th, td {
    vertical-align: middle;
    
    &.is-primary {
      // background-color: rgba($primary-color, 0.05);
      font-weight: bold;
    }
  }

  th {
    background-color: $light-gray;
    font-weight: 600;
    color: $dark-gray;
  }

  td {
    color: $dark-gray;
  }

  tbody tr {
    transition: background-color 0.2s ease;
    
    &:hover {
      background-color: $light-gray;
    }
  }
}

// Button styles
.button {
  &.is-primary {
    background-color: $primary-color;
    
    &:hover {
      background-color: darken($primary-color, 5%);
    }
  }
  
  &.is-secondary {
    background-color: $secondary-color;
    color: $white;
    
    &:hover {
      background-color: darken($secondary-color, 5%);
    }
  }
  
  &.is-light {
    background-color: $light-gray;
    color: $dark-gray;
    
    &:hover {
      background-color: darken($light-gray, 5%);
    }
  }
}

// Responsive adjustments
@media screen and (max-width: 768px) {
  .pricing-card.is-highlighted {
    transform: scale(1);
    
    &:hover {
      transform: translateY(-5px) scale(1);
    }
  }
  
  .comparison-table {
    th, td {
      padding: 0.5rem;
      font-size: 0.9rem;
    }
  }
  
  .registration-section {
    .column {
      padding: 0.5rem;
    }
  }
}
.pricing-form-container{
  .input{
    border-radius: 8px;
  }
}

.colorful-box {
  background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
  border-radius: 12px;
  padding: 1.5rem;
  color: #fff;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);

  h3 {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1rem;
    opacity: 0.9;
  }

  a {
    background-color: rgba(255, 255, 255, 0.2);
    border: none;
    color: #fff;
    padding: 0.75rem 1.5rem;
    font-weight: bold;
    border-radius: 6px;
    transition: background 0.3s ease-in-out;

    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
    }
  }
}